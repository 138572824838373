import Swal from 'sweetalert2'

export default class Etape2 extends HTMLDivElement {
    constructor() {
        super();

        this.$btSendDevis = this.querySelector("#send-mail-devis");
        this.SendDevisUrl = this.getAttribute("data-send-devis-url");
    }

    connectedCallback() {
        let self = this;

        this.$btSendDevis.addEventListener("click", () => {
            Swal.fire({
                title: "Entrer votre email afin de recevoir le devis",
                input: "email",
                inputAttributes: {
                    autocapitalize: "off"
                },
                validationMessage: "email invalide !",
                showCancelButton: true,
                cancelButtonText: "annuler",
                confirmButtonText: "envoyer le devis",
                showLoaderOnConfirm: true,
                preConfirm: async (email) => {

                    var datas = { email: email };
                    // Configuration de la requ�te
                    fetch(self.SendDevisUrl, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json' // Sp�cification du type de contenu (JSON ici)
                        },
                        body: JSON.stringify(datas) // Conversion des donn�es en JSON
                    })
                        .then(function (response) {
                            if (!response.ok) {
                                Swal.showValidationMessage('une erreur est survenue...');
                            } else {
                                Swal.fire({
                                    icon: "success",
                                    title: 'Ok',
                                    html: "L'email a bien &eacute;t&eacute; envoy&eacute; !",
                                });
                            }
                        })
                        .catch(function (error) {
                            Swal.showValidationMessage('une erreur est survenue...');
                        });

                }
            });
        })
    }
}