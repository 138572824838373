export default class StickyCta extends HTMLDivElement {
    constructor() {
        super();
    }
    connectedCallback() {
        const observer = new IntersectionObserver(
            ([e]) => {
                e.target.classList.toggle("is-sticky", e.intersectionRatio !== 1)
            },
            { threshold: [1] }
        );
        observer.observe(this);
    }
}