export default class PaiementForm extends HTMLFormElement {


    constructor() {
        super();
        this.$btnSubmit = document.getElementById("btnSubmit")
    }

    connectedCallback() {
        this.$btnSubmit.click();
    }
}