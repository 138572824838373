import markerImg from '../../img/visuels/custom-map-marker.svg';
import markerImgActive from '../../img/visuels/custom-map-marker-active.svg';

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

export default class SearchMap extends HTMLElement {

    constructor() {
        super();
        this.assoItems = this.querySelectorAll('.assos-list-item');
        this.mapContainer = this.querySelector('.map');
        this.assosDetails = this.querySelectorAll('.assos-details');
        this.icon = null;
        this.iconActive = null;
        this.map = null;
        this.markers = [];

        this.$closeCard = this.querySelectorAll(".js-icon-card-close")

        this.markersDatas = [];

    }

    connectedCallback() {
        let self = this;
        this.markersDatas = $('#jsonHotels').data().json;

        this.assoItems.forEach(assoItem => {
            assoItem.addEventListener('click', (e) => {
                this.setCurrentAsso(parseInt(e.currentTarget.dataset.index));
            });
        });

        this.$closeCard.forEach(clcard => {
            clcard.addEventListener('click', (e) => {
                e.currentTarget.parentElement.classList.remove('active');
            });
        });

        this.map = L.map(this.mapContainer).setView([48.111778, -1.680260], 12);

        L.control.zoom({
            position: 'bottomright'
        }).addTo(this.map);

        this.map.removeControl(this.map.zoomControl);

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(this.map);

        this.icon = L.icon({
            iconUrl: markerImg,
            iconSize: [30, 45],
            iconAnchor: [15, 28]
        });

        this.iconActive = L.icon({
            iconUrl: markerImgActive,
            iconSize: [30, 45],
            iconAnchor: [15, 28]
        });

        this.markersLayer = L.layerGroup().addTo(this.map);

        this.markersDatas.forEach(markerData => {
            const marker = L.marker([markerData.lat, markerData.lng], {
                icon: L.divIcon({
                    html: markerData.price,
                    className: 'price-map',
                }),
                index: markerData.index
            });

            marker.on('click', () => {
                this.setCurrentAsso(markerData.index);
            });

            this.markersLayer.addLayer(marker);
            this.markers.push(marker);
        });

        const bounds = this.calculateMarkersBounds();
        this.map.fitBounds(bounds);
    }

    setCurrentAsso(index) {
        this.markers.forEach(marker => {
            if (marker.options.index === index) {
                this.map.panTo(marker.getLatLng());
                this.assosDetails.forEach(assoItem => {
                    assoItem.classList.toggle('active', parseInt(assoItem.dataset.index) === index);
                });
            }
        });
    }

    calculateMarkersBounds() {
        const bounds = L.latLngBounds();

        this.markersDatas.forEach(markerData => {
            bounds.extend([markerData.lat, markerData.lng]);
        });

        return bounds;
    }
}