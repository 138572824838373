// JS pour la partie vehicule du widget de recherche
export default class SearchWidgetVehicule extends HTMLDivElement {

    constructor() {
        super();

        this.$carInputs = this.querySelectorAll(".js-panel-car .counter-car input")
        this.$btnValidateCar = this.querySelector(".js-btn-validate-car")
    }

    connectedCallback() {
        let self = this;
      
        // Event clic sur la checkbox pour dire si il n'y a que des voyageurs pietons
        $(".switch").on("click", "#voyageursPietonCheck", function (evt) {
            if (evt.currentTarget.checked == true) {
                $('#vehiculeStandard').val(0);
                $('#vehiculeMedium').val(0);
                $('#vehiculeMoto').val(0);
                $('.js-number-car')[0].innerHTML = '<strong>Voyageurs piétons<strong/>'
                $('#Pietons').val(true)
            } else {
                $('#Pietons').val(false)
            }
            self.$btnValidateCar.disabled = false
        })

        // Event sur le change des valeurs + ou - de la partie vehicule
        this.$carInputs.forEach(($carInput) => {
            $carInput.addEventListener("change", (e) => {

                // On bloque a 3 le nombre de vehicule standard ou medium
                if (e.currentTarget.id == "vehiculeStandard" || e.currentTarget.id == "vehiculeMedium") {
                    var totalVehc = parseInt(document.querySelector("#vehiculeStandard").value) + parseInt(document.querySelector("#vehiculeMedium").value)
                    if (totalVehc > 3) {
                        document.querySelector("#textMaxMotoCar").classList.add("text-danger")
                        e.currentTarget.value--;
                        return;
                    }
                }

                if (e.currentTarget.value > 0) {
                    $(".js-number-car")[0].innerHTML = `<strong>${this.$carInputs[0].value > 0 ? `${this.$carInputs[0].value} véhicule(s) standard` : ''}
                            ${this.$carInputs[0].value > 0 && this.$carInputs[1].value > 0 ? `, ` : ''}${this.$carInputs[1].value > 0 ? `${this.$carInputs[1].value} véhicule(s) medium` : ''}
                             ${this.$carInputs[2].value > 0 && (this.$carInputs[0].value > 0 || this.$carInputs[1].value > 0) ? `, ` : ''}${this.$carInputs[2].value > 0 ? `${this.$carInputs[2].value} moto(s)` : ''}</strong>`
                    if ($('#voyageursPietonCheck')[0].checked == true) {
                        $('#voyageursPietonCheck').click();
                    }
                } else {
                    $(".js-number-car")[0].innerHTML = `<strong>${this.$carInputs[0].value > 0 ? `${this.$carInputs[0].value} véhicule(s) standard` : ''}
                            ${this.$carInputs[0].value > 0 && this.$carInputs[1].value > 0 ? `, ` : ''}${this.$carInputs[1].value > 0 ? `${this.$carInputs[1].value} véhicule(s) medium` : ''}
                             ${this.$carInputs[2].value > 0 && (this.$carInputs[0].value > 0 || this.$carInputs[1].value > 0) ? `, ` : ''}${this.$carInputs[2].value > 0 ? `${this.$carInputs[2].value} moto(s)` : ''}</strong>`
                    if (this.$carInputs[0].value == 0 & this.$carInputs[1].value == 0 && this.$carInputs[2].value == 0 && $('#voyageursPietonCheck')[0].checked == false) {
                        $(".js-number-car")[0].innerText = "Nombre de véhicules"
                        $('#voyageursPietonCheck').click();
                    }
                }

                if (this.$carInputs[0].value > 0 || this.$carInputs[1].value > 0 || this.$carInputs[2].value > 0 || $('#voyageursPietonCheck')[0].checked == true) {
                    this.$btnValidateCar.disabled = false
                    this.$btnValidateCar.addEventListener("click", () => {
                        this._togglePanelCar(false)
                    })
                } else {
                    this.$btnValidateCar.disabled = true
                }
            })
        })
    }
}