import $ from 'jquery';
window.$ = $;
window.jQuery = $;

import 'bootstrap';
import '@ungap/custom-elements';

import 'jquery-ajax-unobtrusive';
import 'jquery-validation';

import 'jquery-validation-unobtrusive';




// Enable tooltips
import Tooltip from "bootstrap/js/dist/tooltip";

const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
tooltipTriggerList.map((elem) => {
    return new Tooltip(elem);
});


// Mount auto-sliders
import SearchHeader from "./js/components/SearchHeader";
import SearchMap from "./js/components/SearchMap";
import SiteHeader from "./js/components/SiteHeader";
import StickyCta from "./js/components/StickyCta";
import InputQuantity from "./js/components/InputQuantity";
import SearchWidget from "./js/components/SearchWidget";
import CardReservation from "./js/components/CardReservation";
import HotelFilter from "./js/components/HotelFilter";
import PhotoGallery from "./js/components/PhotoGallery";
import { initialiserSplide } from "./js/components/Pagination";
import TogglePromo from "./js/components/TogglePromo";
import SearchWidgetChambre from "./js/components/SearchWidgetChambre";
import SearchWidgetVehicule from "./js/components/SearchWidgetVehicule";
import Etape4 from "./js/components/Etape4";
import Etape2 from "./js/components/Etape2";
import PaiementForm from "./js/components/PaiementForm";
import BonCadeau from "./js/pages/BonCadeau";
import PageHotel from "./js/pages/PageHotel";
import PageHome from "./js/pages/PageHome";
import HotelList from "./js/components/HotelList";
import FormCommandBC from "./js/components/FormCommandBC";

// Custom elements
window.addEventListener("DOMContentLoaded", (e) => {
    initialiserSplide();
    customElements.define("search-header", SearchHeader, { extends: 'div' });
    customElements.define("search-map", SearchMap);
    customElements.define("site-header", SiteHeader);
    customElements.define("sticky-cta", StickyCta, { extends: "div" });
    customElements.define("input-quantity", InputQuantity, { extends: "div" });
    customElements.define("search-widget", SearchWidget, { extends: "div" })
    customElements.define("card-reservation", CardReservation, { extends: "div" })
    customElements.define("hotel-filter", HotelFilter, { extends: "div" })
    customElements.define("photo-gallery", PhotoGallery, { extends: "div" });
    customElements.define("toggle-promo", TogglePromo, { extends: "div" });
    customElements.define("search-widget-chambre", SearchWidgetChambre, { extends: "div" });
    customElements.define("search-widget-vehicules", SearchWidgetVehicule, { extends: "div" });
    customElements.define("etape-4", Etape4, { extends: "div" });
	customElements.define("etape-2", Etape2, { extends: "div" });
    customElements.define("paiement-form", PaiementForm, { extends: "form" });
    customElements.define("bon-cadeau-list", BonCadeau, { extends: "div" });
    customElements.define("page-hotel", PageHotel, { extends: "div" });
	customElements.define("page-home", PageHome, { extends: "div" });
    customElements.define("hotel-list", HotelList, { extends: "div" });
	customElements.define("form-commande-bc", FormCommandBC, { extends: "form" });
})

import './scss/main.scss';


// Réecriture des messages du jquery-validate car ils sont en anglais
$(function () {
    $.extend($.validator.messages, {
		required: "Ce champ est obligatoire.",
		remote: "Veuillez corriger ce champ.",
		email: "Veuillez fournir une adresse électronique valide.",
		url: "Veuillez fournir une adresse URL valide.",
		date: "Veuillez fournir une date valide.",
		dateISO: "Veuillez fournir une date valide (ISO).",
		number: "Veuillez fournir un numéro valide.",
		digits: "Veuillez fournir seulement des chiffres.",
		creditcard: "Veuillez fournir un numéro de carte de crédit valide.",
		equalTo: "Veuillez fournir encore la même valeur.",
		notEqualTo: "Veuillez fournir une valeur différente, les valeurs ne doivent pas être identiques.",
		extension: "Veuillez fournir une valeur avec une extension valide.",
		maxlength: $.validator.format("Veuillez fournir au plus {0} caractères."),
		minlength: $.validator.format("Veuillez fournir au moins {0} caractères."),
		rangelength: $.validator.format("Veuillez fournir une valeur qui contient entre {0} et {1} caractères."),
		range: $.validator.format("Veuillez fournir une valeur entre {0} et {1}."),
		// on met ce message la pour min et max car la date s'affiche en format anglais, si besoin de changer a voir
		max: $.validator.format("La date de naissance n'est pas valide pour cet occupant."),
		min: $.validator.format("La date de naissance n'est pas valide pour cet occupant."),
		step: $.validator.format("Veuillez fournir une valeur multiple de {0}."),
		maxWords: $.validator.format("Veuillez fournir au plus {0} mots."),
		minWords: $.validator.format("Veuillez fournir au moins {0} mots."),
		rangeWords: $.validator.format("Veuillez fournir entre {0} et {1} mots."),
		letterswithbasicpunc: "Veuillez fournir seulement des lettres et des signes de ponctuation.",
		alphanumeric: "Veuillez fournir seulement des lettres, nombres, espaces et soulignages.",
		lettersonly: "Veuillez fournir seulement des lettres.",
		nowhitespace: "Veuillez ne pas inscrire d'espaces blancs.",
		ziprange: "Veuillez fournir un code postal entre 902xx-xxxx et 905-xx-xxxx.",
		integer: "Veuillez fournir un nombre non décimal qui est positif ou négatif.",
		vinUS: "Veuillez fournir un numéro d'identification du véhicule (VIN).",
		dateITA: "Veuillez fournir une date valide.",
		time: "Veuillez fournir une heure valide entre 00:00 et 23:59.",
		phoneUS: "Veuillez fournir un numéro de téléphone valide.",
		phoneUK: "Veuillez fournir un numéro de téléphone valide.",
		mobileUK: "Veuillez fournir un numéro de téléphone mobile valide.",
		strippedminlength: $.validator.format("Veuillez fournir au moins {0} caractères."),
		email2: "Veuillez fournir une adresse électronique valide.",
		url2: "Veuillez fournir une adresse URL valide.",
		creditcardtypes: "Veuillez fournir un numéro de carte de crédit valide.",
		currency: "Veuillez fournir une monnaie valide.",
		ipv4: "Veuillez fournir une adresse IP v4 valide.",
		ipv6: "Veuillez fournir une adresse IP v6 valide.",
		require_from_group: $.validator.format("Veuillez fournir au moins {0} de ces champs."),
		nifES: "Veuillez fournir un numéro NIF valide.",
		nieES: "Veuillez fournir un numéro NIE valide.",
		cifES: "Veuillez fournir un numéro CIF valide.",
		postalCodeCA: "Veuillez fournir un code postal valide.",
		pattern: "Format non valide."
    });
})