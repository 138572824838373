import * as fecha from "fecha";
import HotelDatepicker from "hotel-datepicker";


export default class PageHotel extends HTMLDivElement {

    constructor() {
        super();

        // on récupère l'url
        this.RefreshUrl = this.getAttribute("data-refresh-url");
        this.ReloadUrl = this.getAttribute("data-reload-url");

        this.$CardReservationDiv = this.querySelector("#div-reservation-component");

        this.$CheckboxDiner = this.querySelector("#choose-diner");
        this.$DinerInputHidden = this.querySelector("#OptionCheck");
        this.$TraverseIsGoodDayHidden = this.querySelector("#TraverseIsGoodDay");
        this.$Ile = this.querySelector("#Ile");

        // les liste de type de chambre
        this.$Chambres = document.querySelectorAll(".type-chambres-disponibles");
        this.$voyageWidget = document.querySelectorAll(".js-voyage-widget");

        // le composant de date
        this.$datesInput = this.querySelector('.js-dates')
        this.datepicker = new HotelDatepicker(this.$datesInput);
    }

    connectedCallback() {
        let self = this;

        // on lance pour être sur que la carte soit a jour au chargement de la page
        this.refreshCard();

        this.querySelectorAll(".choose-chamber").forEach((radio) => {
            // on s'abonne au click pour chaque radio button
            radio.addEventListener("click", (e) => {
                // on affiche le bouton réserver
                this.refreshCard();
            })
        });

        // on cache les traversees qui ne vont pas sur l'ile de l'hotel
        // on le fait ici car le widget est aussi sur l'accueil
        this.hideTraversee(self);

        if (this.$CheckboxDiner) {
            this.$CheckboxDiner.addEventListener("change", (evt) => {
                this.refreshCard();

                if (evt.currentTarget.checked == true) {
                    self.$DinerInputHidden.value = "true"
                } else {
                    self.$DinerInputHidden.value = "false"
                }
            })
        }



        // Ici on gère les evenements liés au reload de la page

        // ici on récupère l'event du 
        document.addEventListener('HdpckDateSetEvent', function () {
            self.reloadForDates();
        });

        this.querySelectorAll(".choose-chamber").forEach((radio) => {
            // on s'abonne au click pour chaque radio button
            radio.addEventListener("click", (e) => {
                this.refreshCard();
            })
        });

        // on map les evenement necessaires
        self.attacherEvenement(self);

    }

    // Ici on gère les evenements liés au reload de la page
    // => Changement de conf véhicule
    // => Changement de conf chambres
    attacherEvenement(self) {

        // Les boutons du search Widget
        self.$btnValidateGuest = self.querySelector(".js-btn-validate-guest")
        self.$btnValidateVehicule = self.querySelector(".js-btn-validate-car")

        self.$btnValidateGuest.addEventListener("click", (evt) => {
            self.reloadForChambre();
        })

        self.$btnValidateVehicule.addEventListener("click", (evt) => {
            self.reloadForVehicule();
        })
    }

    // afin de recharger la page hotel lors d'un changement dans le searchWidget
    reloadPageHotel(datas) {
        var self = this;

        // Configuration de la requête
        fetch(this.ReloadUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json' // Spécification du type de contenu (JSON ici)
            },
            body: JSON.stringify(datas) // Conversion des données en JSON
        })
            .then(function (response) {
                if (!response.ok) {
                    throw new Error('La requête a échoué');
                }
                // on refresh la page
                window.location.reload();
            })
            .catch(function (error) {
                console.error('une erreur est survenue...');
            });
    }

    // afin de recharger la page hotel causé par un changement de config véhicule
    reloadForVehicule() {
        var self = this;

        // on va récupérer les valeurs 
        var VehicStandard = this.querySelector("#vehiculeStandard").value;
        var VehicMedium = this.querySelector("#vehiculeMedium").value;
        var VehicMotos = this.querySelector("#vehiculeMoto").value;

        // on update la session et on refresh la page
        // Données à envoyer
        const selections = {
            VehicStandard,
            VehicMedium,
            VehicMotos,
            TypeReload: "vehicule"
        };

        self.reloadPageHotel(selections);
    }

    // afin de recharger la page hotel causé par un changement de config véhicule
    reloadForDates() {
        var self = this;

        // on va récupérer les valeurs 
        var DateDepart = this.querySelector('#DateDepart').value
        var DateArrivee = this.querySelector('#DateArrivee').value

        // on refait la date car pas pris en compte par le serveur...
        var dtDp = DateDepart.split("/")
        DateDepart = `${dtDp[2]}-${dtDp[1]}-${dtDp[0]}`
        var drAr = DateArrivee.split("/")
        DateArrivee = `${drAr[2]}-${drAr[1]}-${drAr[0]}`

        // on update la session et on refresh la page
        // Données à envoyer
        const selections = {
            DateDepart,
            DateArrivee,
            TypeReload: "dates"
        };

        self.reloadPageHotel(selections);
    }

    // afin de recharger la page hotel causé par un changement de config chambre
    reloadForChambre() {
        var self = this;

        console.log('ici')


        const selections = {};

        const chambresContainer = document.getElementById('ChambresContainer');
        const chambres = chambresContainer.querySelectorAll('.chambre')
        selections.NbChambres = 0;
        selections.Chambres = [];

        // on va reconstruire la collectio nde chambre
        chambres.forEach(function (chambre) {
            // on incrémente le nombre de chambre
            selections.NbChambres++;

            // on créé l'objet
            var datas = {};

            const inputs = chambre.querySelectorAll('input');
            inputs.forEach(function (input) {
                // Obliger de retravailler le name de l'input 
                const parts = input.name.split('.'); // Diviser la chaîne à chaque occurrence de '.'
                const lastPart = parts[parts.length - 1]; // Récupérer la dernière partie
                datas[lastPart] = input.value; // Utiliser la dernière partie comme clé

                // on voit pour gérer les select d'age des Enfants
                const selectEnfantsAge = chambre.querySelectorAll('.enfantSelect');
                if (selectEnfantsAge) {
                    datas.AgesEnfants = [];
                    selectEnfantsAge.forEach(function (select) {
                        // on ajoute l'age de chaque enfants
                        datas.AgesEnfants.push(select.value);
                    });
                }
            });

            selections.Chambres.push(datas);
        });

        selections.TypeReload = "chambre";

        self.reloadPageHotel(selections);
    }

    // afin de refresh le composant de réservation
    refreshCard() {

        var self = this;

        var datas = [];

        // il faut récupérer la value chaque radio button checked
        self.$Chambres.forEach((chambre) => {

            // Le numéro de la chambre
            const chambreid = chambre.getAttribute("data-chambreid");
            const typeChambre = chambre.querySelectorAll(".choose-chamber");

            typeChambre.forEach((radio) => {
                if (radio.checked) {
                    // l'id du type de chambre sélectionné
                    const radioValue = radio.value;
                    // on ajoute tout ça à la liste (Bien respecter les noms de propriétés)
                    datas.push({ chambreid, typeChambreChoisiId: radioValue });
                }
            });
        });

        var addOptionDiner = false;

        // on check si le diner est compris
        if (self.$CheckboxDiner && self.$CheckboxDiner.checked) {
            addOptionDiner = true;
        }

        var traverseIsGoodDay = true;
        if (self.$TraverseIsGoodDayHidden) {
            traverseIsGoodDay = self.$TraverseIsGoodDayHidden.value;
        }

        // on format l'objet de sortie
        var selections = {
            AddOptionDiner: addOptionDiner,
            TypeChambreSelections: datas,
            TraverseIsGoodDay: traverseIsGoodDay
        }

        // Configuration de la requête
        fetch(this.RefreshUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json' // Spécification du type de contenu (JSON ici)
            },
            body: JSON.stringify(selections) // Conversion des données en JSON
        })
            .then(function (response) {
                if (!response.ok) {
                    throw new Error('La requête a échoué');
                }
                return response.text(); // Récupérer la réponse en tant que texte brut
            })
            .then(function (htmlContent) {
                // Mettre à jour le contenu HTML de la carte de réservation
                self.$CardReservationDiv.innerHTML = htmlContent;

                // on map les evenement necessaires
                self.attacherEvenement(self);
                self.hideTraversee(self);
            })
            .catch(function (error) {
                console.error('une erreur est survenue...');
            });

    }

    // cache les traversees pas lies a l'hotel
    hideTraversee(self) {
        self.$voyageWidget.forEach((voy) => {
            if (self.$Ile.value == "Jersey" && (voy.id == "voy_2" || voy.id == "voy_6")) {
                document.querySelector("#" + voy.id).style.display = "none";
                document.querySelector("#" + voy.id).classList.remove("d-flex");
                document.querySelector("#" + voy.id).classList.add("d-none");
            } else if (self.$Ile.value == "Guernesey" && (voy.id == "voy_0" || voy.id == "voy_4")) {
                document.querySelector("#" + voy.id).style.display = "none";
                document.querySelector("#" + voy.id).classList.remove("d-flex");
                document.querySelector("#" + voy.id).classList.add("d-none"); 
            }
        });
    }
}