// JS pour la partie chambre du widget de recherche
export default class SearchWidgetChambre extends HTMLDivElement {

    constructor() {
        super();

        this.$numberGuest = this.querySelector(".js-number-guests")
        this.$numberGuestInside = this.querySelector(".js-number-guests-inside")
        this.$guestsInputs = this.querySelectorAll(".js-panel-guests .counter-guests input")
        this.$chambreInput = document.getElementById("chambreSelector")
   
        this.$btnValidateVehicule = this.querySelector(".js-btn-validate-car")        
    }

    connectedCallback() {
        let self = this;
        let compteurEnfants = 0;
        let compteurChambres = 1;
        let compteurAdultes = 0;

        // on va calculer le nb d'enfant pour init
        compteurEnfants = self._calculateCompteurEnfant();
        compteurAdultes = self._calculateCompteurAdulte();

        // Event clic sur le bouton + pour une chambre
        $(".js-panel-guests").on("click", ".js-up-chambre", function (evt) {
            evt.preventDefault()
            self.$chambreInput.stepUp()

            compteurEnfants = self._calculateCompteurEnfant();
            compteurAdultes = self._calculateCompteurAdulte();

            if ($("#ChambresContainer .chambre").last()[0].id != 'maChambre_5') {
                if ((compteurAdultes + compteurEnfants <= 10)) {
                    var nombreChambres = $(self.$chambreInput).val();
                    self._genererChambre(nombreChambres, (compteurAdultes + compteurEnfants == 9 ? 1 : 2));
                    compteurChambres++;
                    self._ecrireGuest(compteurChambres, self)
                } else {
                    self.$chambreInput.value--
                }
               
            } else {
                evt.currentTarget.disabled = true;
                $(evt.currentTarget).addClass("disabledButton")
            }
        })

        // Event clic bouton - pour une chambre
        $(".js-panel-guests").on("click", ".js-down-chambre", function (evt) {
            evt.preventDefault()
            self.$chambreInput.stepDown()
            $('.js-up-chambre')[0].disabled = false;
            $('.js-up-chambre').removeClass("disabledButton")

            if ($("#ChambresContainer .chambre").last()[0].id != 'maChambre_1') {
                var nombreChambres = parseInt($(self.$chambreInput).val());
                nombreChambres = nombreChambres - 1;
                $("#ChambresContainer .chambre").last().remove();
                compteurChambres--;
                self._ecrireGuest(compteurChambres, self)
            }
        })

        // Event clic le bouton + d'ajout d'un enfant
        $(".js-panel-guests").on("click", ".js-up-enfant", function (evt) {

            evt.preventDefault()
            let inputName = '#' + evt.currentTarget.dataset.inputId
            let numeroChambre = $(inputName).data().id;

            let lastEnfantSelect = $("#maChambre_" + numeroChambre + " .enfantSelect").last()
            let nombreEnfant = 1;
            if (lastEnfantSelect.length > 0) {
                nombreEnfant = parseInt(lastEnfantSelect.data().id) + 1;
            }
            
            if (nombreEnfant <= 2) {
                compteurEnfants++
                if ((compteurAdultes + compteurEnfants <= 10)) {
                    $(inputName)[0].stepUp()
                    $('#enfantPart_' + numeroChambre).show()
                    self._genererListEnfant(nombreEnfant, numeroChambre)
                    self._ecrireGuest(compteurChambres, self)
                }
            }
        })

        // Event clic sur le bouton - d'un enfant
        $(".js-panel-guests").on("click", ".js-down-enfant", function (evt) {
            evt.preventDefault()
            let inputName = '#' + evt.currentTarget.dataset.inputId
            let numeroChambre = $(inputName).data().id;
            $(inputName)[0].stepDown()
            if ($(inputName).val() == 0) {
                $('#enfantPart_' + numeroChambre).hide()
            }
            $("#maChambre_" + numeroChambre + " .enfantSelect").last().remove()
            compteurEnfants--
            self._ecrireGuest(compteurChambres, self)
        })

        $(".js-panel-guests").on("change", "input", function (evt) {
            compteurEnfants = self._calculateCompteurEnfant();
            compteurAdultes = self._calculateCompteurAdulte();
            if (compteurAdultes + compteurEnfants <= 10) {
                self._ecrireGuest(compteurChambres, self)
            } else {
                evt.target.value--;
            }
        })
    }

    _ecrireGuest(nbChambres, self) {
        let nbAdultes = self._calculateCompteurAdulte();
        let nbEnfants = self._calculateCompteurEnfant();
        $('.js-number-guests-inside')[0].innerHTML = $('.js-number-guests')[0].innerHTML = `<strong>${nbChambres} chambre(s)${nbAdultes > 0 ? `, ${nbAdultes} adulte(s)` : ''}${nbEnfants > 0 ? `, ${nbEnfants} enfant(s)` : ''}<strong>`
    }

    /// Calcule le nombres d'adultes
    _calculateCompteurAdulte() {
        let compteurAdultes = 0
        $('.js-adulte').each(function (e) {
            compteurAdultes += parseInt($(this).val())
        })
        return compteurAdultes;
    }

    /// Calcule le nombre d'enfants
    _calculateCompteurEnfant() {
        let compteurEnfants = 0
        $('.js-enfant').each(function (e) {
            compteurEnfants += parseInt($(this).val())
        })
        return compteurEnfants;
    }

    /// Call Ajax pour recuperer le html d'une chambre
    _genererChambre(numero, nombreAdulte) {
        $.ajax({
            url: '/Home/GetSearchWidgetChambreItem',
            type: 'GET',
            type: 'POST',
            data: { nombreChambres: numero, nbAdultes: nombreAdulte },
            success: function (data) {
                $("#ChambresContainer").append(data);
            },
            error: function (error) {
                console.log(error);
            }
        });

    }

    /// Genere un code html pour une dropdown d'enfant
    _genererListEnfant(nombreEnfants, numeroChambre) {
        var selectHtml = '<select class="form-select g-col-12 g-col-lg-2 enfantSelect" name="Chambres[' + (numeroChambre - 1) + '].AgesEnfants[' + (nombreEnfants - 1) + ']" data-id="' + nombreEnfants + '">';
        if (nombreEnfants == 1) {
            selectHtml += '<option>Âge du ' + nombreEnfants + 'er enfant</option>';
        } else {
            selectHtml += '<option>Âge du ' + nombreEnfants + 'ème enfant</option>';
        }

        selectHtml += '<option value="0">Moins de 1 an</option>';

        for (var j = 1; j < 16; j++) {
            selectHtml += '<option value="' + j + '">' + j + ' an(s)</option>';
        }
        selectHtml += '</select>';

        $('#enfantSelect_' + numeroChambre).append(selectHtml);
    }



}