import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipe from 'photoswipe';

export default class PhotoGallery extends HTMLDivElement {

    constructor() {
        super();
    }

    connectedCallback() {
        console.log('ici')
        this._loadLightbox()
    }

    async _loadLightbox () {
        const promises = []

        this.querySelectorAll('#js-gallery .pswp-url').forEach($a => {
            const p = new Promise(resolve => {
                const img = new Image()

                img.onload = () => {
                    $a.dataset.pswpWidth = img.width.toString()
                    $a.dataset.pswpHeight = img.height.toString()
                    resolve()
                }

                img.onerror = () => {
                    // don't care => will fail later
                    $a.dataset.pswpWidth = '1'
                    $a.dataset.pswpHeight = '1'
                    resolve()
                }
                img.src = $a.href
            })

            promises.push(p)
        })

        // wait all img loaded
        await Promise.all(promises)

        // then init lightbox
        const lightbox = new PhotoSwipeLightbox({
            gallery: '#js-gallery',
            children: '.pswp-url',
            pswpModule: PhotoSwipe,
            hideAnimationDuration: 0,
            showAnimationDuration: 0,

            closeTitle: 'Fermer',
            zoomTitle: 'Agrandir',
            arrowPrevTitle: 'Voir l\'image précédente',
            arrowNextTitle: 'Voir l\'image suivante',
            errorMsg: 'L\'image n\'a pas pu être chargée',
            indexIndicatorSep: ' sur ',
        });

        lightbox.init()
    }
}