export default class CardReservation extends HTMLDivElement {


    constructor() {
        super();
        this.$jsReservationCard = document.querySelector(".js-reservation-card")
        this.$jsRecapMobile = document.querySelector(".js-recap-mobile")
        this.$priceFooter = document.querySelector(".price-recap-detail")

        this.$choicesReservation = document.querySelectorAll(".custom-checkbox")
        this.$choicesReservationInput = document.querySelectorAll(".custom-checkbox input")

        this.$jsCLoseCardReservation = this.querySelector(".js-close-card-reservation")
    }

    connectedCallback() {

        const $screenHeight = window.innerHeight;

        if(this.$jsReservationCard.offsetHeight > $screenHeight) {
            this.$jsReservationCard.style.position = "static"
        }

        this.$priceFooter.addEventListener("click", () => {
            this.$jsReservationCard.classList.add("open")
        })

        this.$choicesReservation.forEach(($choiceReservation) => {
            $choiceReservation.addEventListener("click", () => {
                this.$choicesReservationInput.forEach(($choiceReservationInput) => {
                    if($choiceReservationInput.checked) {
                        this.$jsRecapMobile.classList.remove("d-none")
                    }
                })
            })
        })

        if (this.$jsCLoseCardReservation) {
            this.$jsCLoseCardReservation.addEventListener("click", () => {
                this.$jsReservationCard.classList.remove("open")
            })
        }
    }
}