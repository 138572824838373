import HotelDatepicker from "hotel-datepicker";

export default class SearchWidget extends HTMLDivElement {

    constructor() {
        super();
        this.$searchWidget = document.querySelector(".search-widget")

        this.$togglePanelPlace = this.querySelector(".js-toggle-panel-place")
        this.$togglePanelPeriod = this.querySelector(".js-toggle-panel-period")
        this.$togglePanelGuests = this.querySelector(".js-toggle-panel-guests")
        this.$togglePanelCar = this.querySelector(".js-toggle-panel-car")

        this.$panelPlace = this.querySelector(".js-panel-place")
        this.$panelPeriod = this.querySelector(".js-panel-period")
        this.$panelGuests = this.querySelector(".js-panel-guests")
        this.$panelCar = this.querySelector(".js-panel-car")

        this.$closePanel = this.querySelectorAll(".js-close-panel")

        // WIDGET SELECTION VOYAGE
        this.$placeSelection = this.querySelector(".js-place-selection")
        this.$labelPlaceSelection = this.querySelectorAll(".js-label-place-selection")
        this.$inputPlaceSelection = this.querySelectorAll(".js-input-place-selection")

        // WIDGET SELECTION PERIOD
        this.$datesInput = this.querySelector('.js-dates')
        this.$selectionDate = this.querySelector(".js-date-period")
        // les dates formatées
        this.minDate = this.$panelPeriod.getAttribute("data-minDate");
        this.departHdpck = this.$panelPeriod.getAttribute("data-depart");
        this.arriveeHdpck = this.$panelPeriod.getAttribute("data-arrivee");
        // event du set de la date
        this.HdpckDateSetEvent = new Event('HdpckDateSetEvent');

        // OVERLAY
        this.$overlayWidget = this.querySelector(".overlay-widget")

        // MODALE BOOTSTRAP
        this.$modalWidget = document.getElementById("modalCar")
        this.$isModalOpen = false
    }

    connectedCallback() {

        this.$modalWidget.addEventListener('show.bs.modal', event => {
            this.$isModalOpen = true
        })

        this.$modalWidget.addEventListener('hidden.bs.modal', event => {
            this.$isModalOpen = false
        })

        this.$togglePanelPlace.addEventListener("click", () => {
            this._togglePanelPlace(!this.$panelPlace.classList.contains('opened'))
            this._togglePanelPeriod(false)
            this._togglePanelGuests(false)
            this._togglePanelCar(false)
            this._toggleOverlayWidget(true)
        })

        this.$togglePanelPeriod.addEventListener("click", () => {
            this._togglePanelPlace(false)
            this._togglePanelPeriod(!this.$panelPeriod.classList.contains('opened'))
            this._togglePanelGuests(false)
            this._togglePanelCar(false)
            this._toggleOverlayWidget(true)
        })

        this.$togglePanelGuests.addEventListener("click", () => {
            this._togglePanelPlace(false)
            this._togglePanelPeriod(false)
            this._togglePanelGuests(!this.$panelGuests.classList.contains('opened'))
            this._togglePanelCar(false)
            this._toggleOverlayWidget(true)
        })

        this.$togglePanelCar.addEventListener("click", () => {
            this._togglePanelPlace(false)
            this._togglePanelPeriod(false)
            this._togglePanelGuests(false)
            this._togglePanelCar(!this.$panelCar.classList.contains('opened'))
            this._toggleOverlayWidget(true)
        })

        this.$labelPlaceSelection.forEach((label, index) => {
            label.addEventListener("click", () => {
                this._closeAllPanel();
                this.$placeSelection.innerHTML = `<strong>${this.$inputPlaceSelection[index].dataset.label}</strong>`
            })
        })

        for (let i = 0; i < this.$closePanel.length; i++) {
            this.$closePanel[i].addEventListener("click", () => {
                this._closeAllPanel()
            })
        }

        // On ferme les panels si l'utilisateur click en dehors du composant
        document.addEventListener('click', e => {
            if (!this.$isModalOpen) {
                if (!this.contains(e.target)) {
                    this._togglePanelPlace(false)
                    this._togglePanelPeriod(false)
                    this._togglePanelGuests(false)
                    this._togglePanelCar(false)
                    this._toggleOverlayWidget(false)
                }
            }
        })

       
        this.$overlayWidget.addEventListener("click", () => {
            this._closeAllPanel()
        })

        this.Hdpkr = new HotelDatepicker(this.$datesInput, {
            inline: true,
            format: "DD/MM/YYYY",
            startDate:this.minDate,
            onSelectRange: () => this._dateSelected(),
            i18n: {
                selected: 'Votre séjour:',
                night: 'Nuit',
                nights: 'Nuits',
                button: 'Fermer',
                clearButton: 'Réinitialiser',
                submitButton: 'Valider',
                'checkin-disabled': 'Arrivée désactivée',
                'checkout-disabled': 'Départ désactivé',
                'day-names-short': ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
                'day-names': ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
                  'month-names-short': ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec'],
                'month-names': ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Decembre'],
                'error-more': 'La période ne doit pas contenir plus d\'une nuit',
                'error-more-plural': 'La période ne doit pas contenir plus de %d nuits',
                'error-less': 'La période ne doit pas contenir moins d\'une nuit',
                'error-less-plural': 'La période ne doit pas contenir moins de %d nuits',
                'info-more': 'Veuillez sélectionner une période contenant au moins 1 nuit',
                'info-more-plural': 'Veuillez sélectionner une période contenant au moins %d nuits',
                'info-range': 'Veuillez sélectionner une période contenant entre %d et %d nuits',
                'info-range-equal': 'Veuillez sélectionner une période contenant %d nuits',
                'info-default': 'Veuillez sélectionner une période',
                'aria-application': 'Calendrier',
                'aria-selected-checkin': 'Sélectionné comme date d\'arrivée, %s',
                'aria-selected-checkout': 'Sélectionné comme date de départ, %s',
                'aria-selected': 'Sélectionné, %s',
                'aria-disabled': 'Non disponible, %s',
                'aria-choose-checkin': 'Choisissez %s comme date d\'arrivée',
                'aria-choose-checkout': 'Choisissez %s comme date de départ',
                'aria-prev-month': 'Mois précédent',
                'aria-next-month': 'Mois suivant',
                'aria-close-button': 'Fermer le calendrier',
                'aria-clear-button': 'Réinitialiser la sélection',
                'aria-submit-button': 'Soumettre'
            }
        })

        // afin de lui faire sélectionner les bonnes dates
        this.Hdpkr.setRange(this.departHdpck,this.arriveeHdpck)
    }

    _togglePanelPlace(open = true) {
        if (this.$panelPlace) {
            this.$panelPlace.classList.toggle('opened', open)
        }
    }

    _togglePanelPeriod(open = true) {
        if (this.$panelPeriod) {
            this.$panelPeriod.classList.toggle('opened', open)
        }
    }

    _togglePanelGuests(open = true) {
        if (this.$panelGuests) {
            this.$panelGuests.classList.toggle('opened', open)
        }
    }

    _togglePanelCar(open = true) {
        if (this.$panelCar) {
            this.$panelCar.classList.toggle('opened', open)
        }
    }

    _toggleOverlayWidget(active = true) {
        if(this.$searchWidget) {
            this.$searchWidget.classList.toggle('active', active)
        }
    }

    _closeAllPanel() {
        this._togglePanelPlace(false)
        this._togglePanelPeriod(false)
        this._togglePanelGuests(false)
        this._togglePanelCar(false)
        this._toggleOverlayWidget(false)
    }

    _dateSelected() {
        if (this.$panelPeriod) {
            this._togglePanelPeriod(false)
            this._toggleOverlayWidget(false)
            this.$selectionDate.innerHTML = `<strong>${this.$datesInput.value}</strong>`
            var splitDate = this.$datesInput.value.split(' - ')
            this.querySelector('#DateDepart').value = splitDate[0]
            this.querySelector('#DateArrivee').value = splitDate[1]

            // on balance l'évenement
            document.dispatchEvent(this.HdpckDateSetEvent);
        }
    }
}