export default class TogglePromo extends HTMLDivElement {
    constructor() {
        super();
        this.$btnTogglePromo = this.querySelector(".js-toggle-promo")
        this.$promoRecap = this.querySelector(".promo-panel")
        this.$jsClosePromo = this.querySelector(".js-close-promo")
        this.$inputCodeReduction = document.getElementById("CodeReduction")
        this.$bonCadeauId = document.getElementById("Id")
        this.$modeLivraison = document.getElementById("ModeLivraison")
        this.$quantite = document.getElementById("Quantite")
        this.$tarifLivraison = document.getElementById("TarifLivraison")
        this.$urlCheck = document.getElementById("widget-bon-cadeau").getAttribute('data-url-check');
        this.$urlModeLivraison = document.getElementById("widget-bon-cadeau").getAttribute("data-url-mode-livraison");
    }

    connectedCallback() {
        this.$btnTogglePromo.addEventListener("click", () => {
            this.$promoRecap.classList.add("open")
        })

        this.$jsClosePromo.addEventListener("click", () => {
            this.$promoRecap.classList.remove("open")
        })

        var thisObj = this;

        //Application du code de réduction
        this.$inputCodeReduction.addEventListener('input', function () {
            var timeoutId;

            clearTimeout(timeoutId);
            timeoutId = setTimeout(function () {
                var codeReduction = $("#CodeReduction").val();

                $.ajax({
                    url: thisObj.$urlCheck,
                    type: 'POST',
                    data: { codeReduction: codeReduction, bonCadeauId: thisObj.$bonCadeauId.value, tarifLivraison: thisObj.$tarifLivraison.value, quantite: thisObj.$quantite.value },
                    success: function (result) {
                        if (result.success) {
                            $("#codeReductionValidationMessage").hide();
                            $("#codeReductionValide").text(result.message);
                            $("#prix-total").text(result.montant + " €");
                            $("#MontantReduction").val(result.montantReduction);
                        } else {
                            $("#codeReductionValidationMessage").text(result.message);
                        }
                    },
                    error: function () {
                        alert('Une erreur s\'est produite lors de l\'application de la réduction.');
                    }
                });
            }, 1000);
        });

        //Changement du mode de livraison
        this.$modeLivraison.addEventListener("change", function (e) {
            var modeLivraison = this.value;
            var selectElement = document.getElementById('ModeLivraison');
            var selectedOption = selectElement.options[selectElement.selectedIndex];
            var dataPrice = selectedOption.getAttribute('data-price');

            $.ajax({
                url: thisObj.$urlModeLivraison,
                type: 'POST',
                data: { modeLivraison: modeLivraison, bonCadeauId: thisObj.$bonCadeauId.value },
                error: function () {
                    alert('Une erreur s\'est produite lors du changement de mode de livraison.');
                }
            }).done(function (result) {
                $('.widget-bon-cadeau').html(result);
                $('#ModeLivraison option:eq(' + modeLivraison + ')').prop('selected', true);
            });;
        });

        //Recalcul du prix en fonction de la quantité
        this.$quantite.addEventListener("change", function (e) {
            var quantite = this.value;
            var prixTtc = $("#PrixTTC").val();
            var montantReduction = $("#MontantReduction").val();
            var tarifLivraison = $("#TarifLivraison").val();
            var newPrixTotal = (parseFloat(quantite) * (parseFloat(prixTtc.replace(',', '.'))) - (parseFloat(quantite) * parseFloat(prixTtc.replace(',', '.')) * parseFloat(montantReduction.replace(',', '.')) / 100)) + parseFloat(tarifLivraison.replace(',', '.'));
            $("#prix-total").text(newPrixTotal.toFixed(2).replace('.', ',') + " €");
        });

        //Submit du formulaire 
        var formulaire = document.getElementById('commandForm');

        formulaire.addEventListener('submit', function (event) {
            // Empêcher la soumission automatique du formulaire
            event.preventDefault();

            // Logique de vérification de selection du mode de livraison
            var modeLivraison = document.getElementById('ModeLivraison').value;

            if (modeLivraison == 1 || modeLivraison == 2 || modeLivraison == 3) {
                // Soumettre le formulaire
                formulaire.submit();
            } else {
                // Afficher un message d'erreur
                var errorMessage = document.getElementById('ModeLivraisonError');
                errorMessage.textContent = "Veuillez sélectionner un mode de livraison";
            }
        });


    }
}