export default class HotelList extends HTMLDivElement {

    constructor() {
        super();
        this.$listHotel = this.querySelector("#listHotels");
        this.$mapHotel = this.querySelector("#mapHotels");
        this.$moveToMap = this.querySelector("#moveToMap");
        this.$moveToListHotel = this.querySelector("#moveToListHotel");
    }

    connectedCallback() {
        let self = this;
        this.$moveToMap.addEventListener("click", (evt) => {
          
            self.$mapHotel.classList.remove("invisible");
            self.$listHotel.classList.add("d-none");
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // pour un d�filement fluide, si pris en charge
            });
           
        })

        this.$moveToListHotel.addEventListener("click", (evt) => {

            self.$mapHotel.classList.add("invisible");
            self.$listHotel.classList.remove("d-none");

        })
    }
}