export default class InputQuantity extends HTMLDivElement {

    constructor() {
        super()
        this.$input = this.querySelector('input')
        this.$up = this.querySelector('.js-up')
        this.$down = this.querySelector('.js-down')
    }

    connectedCallback() {
        this._render()

        if (this.$up) {
            this.$up.addEventListener('click', evt => {
                evt.preventDefault()
                this.$input.stepUp()
                this._render()
                this.$input.dispatchEvent(new Event('change', { bubbles: true }))
            })
        }

        if (this.$down) {
            this.$down.addEventListener('click', evt => {
                evt.preventDefault()
                this.$input.stepDown()
                this._render()
                this.$input.dispatchEvent(new Event('change', { bubbles: true }))
            })
        }

        this.$input.addEventListener('change', evt => {
            evt.preventDefault()
            this._render()
        })

    }

    setValue(value) {
        this.$input.value = value
    }

    getValue() {
        return this.$input.value
    }

    _render() {
        if (this.$down) {
            this.$down.disabled = this.getValue() <= parseInt(this.$input.getAttribute("min"))
        }
    }

}
