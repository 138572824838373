export default class Etape4 extends HTMLDivElement {
    constructor() {
        super();

        this.$codeReduction = document.querySelector("#codeReduction")
        this.$btnCodeReduction = document.querySelector("#btnCodeReduction")
        this.$selectAgenceId = document.querySelector("#selectAgenceId")
        this.$invalidCode = document.querySelector("#invalidCode")
        this.$prixReduction = document.querySelector("#prixReduction")
        this.$prixTotal = document.querySelector("#prixTotal")
        this.$email = document.querySelector("#Email")
        this.$adresse = document.querySelector("#Adresse")
        this.$ville = document.querySelector("#Ville")
        this.$codePostal = document.querySelector("#CodePostal")
        this.$telDomicile = document.querySelector("#TelDomicile")

    }

    connectedCallback() {
        let self = this;
        this.$btnCodeReduction.addEventListener("click", () => {
            var codeReduc = self.$codeReduction.value;
            if (codeReduc != undefined && codeReduc != "") {

                // call Ajax pour checker la reduction
                $.ajax({
                    url: self.$btnCodeReduction.dataset.url,
                    type: 'POST',
                    data: { codeReduction: codeReduc },
                    success: function (data) {
                        if (data.status == 0) {
                            // il y a eu une erreur a la vlaidation  de la reduction
                            // on affiche la div avec le message d'erreur
                            $(self.$invalidCode).show();

                            // on enleve et ajoute les styles n�cessaires
                            $(self.$codeReduction).removeClass("is-valid");
                            $(self.$btnCodeReduction).addClass("border-primary text-primary bg-transparent fs-7 fs-lg-6")
                                .removeClass("btn-success text-white fs-6");
                            $(self.$btnCodeReduction).text(' Valider mon code ')

                            // on cache la partie qui affiche le montant de la reduction
                            $(self.$prixReduction).parent().removeClass("d-flex")
                            $(self.$prixReduction).parent().hide()

                            // on met a jour le prix
                            $(self.$prixTotal).text(data.prixTotal + '\u20ac')
                        } else if (data.status == 1) {
                            // La reduction � bien fonctionne
                            // On cache la div d'erreur
                            $(self.$invalidCode).hide();

                            // on enleve et ajoute les styles n�cessaires
                            $(self.$codeReduction).addClass("is-valid");
                            $(self.$btnCodeReduction).removeClass("border-primary text-primary bg-transparent fs-7 fs-lg-6")
                                .addClass("btn-success text-white fs-6");

                            // on affiche la reduction
                            $(self.$btnCodeReduction).html('<i class="condor-icon condor-icon-check fs-3 lh-1"></i>')
                            $(self.$prixReduction).text('-' + data.montantReduction + '\u20ac')
                            $(self.$prixReduction).parent().addClass("d-flex")
                            $(self.$prixReduction).parent().show()

                            // on met a jour le prix
                            $(self.$prixTotal).text(data.prixTotal + '\u20ac')
                        }
                    },
                    error: function (error) {
                    }
                });
            }
        })

        if (this.$selectAgenceId) {
            this.$selectAgenceId.addEventListener("change", (e) => {
                // R�cup�rer l'index de l'option s�lectionn�e
                var selectedIndex = e.target.selectedIndex;
                // R�cup�rer l'option s�lectionn�e � partir de l'index
                var selectedOption = e.target.options[selectedIndex];
                
                this.$email.value = selectedOption.dataset.email
                this.$adresse.value = selectedOption.dataset.adresse
                this.$ville.value = selectedOption.dataset.ville
                this.$codePostal.value = selectedOption.dataset.cp
                this.$telDomicile.value = selectedOption.dataset.tel
            })
        }
    }
}