export default class PageHome extends HTMLDivElement {

    constructor() {
        super();
        this.$btnValidateGuest = document.querySelector(".js-btn-validate-guest")
        this.$btnValidateCar = document.querySelector(".js-btn-validate-car")
        this.$btnDiscoverHotels = document.querySelector(".js-dicover-hotels")
    }

    connectedCallback() {
        let self = this;
        this.$btnValidateGuest.addEventListener("click", (evt) => {
            let active = document.querySelector(".js-close-panel")
            active.click()
        })

        this.$btnDiscoverHotels.addEventListener("click", (evt) => {

            // on va check si le searchWidget est présent
            let formSearchWidget = document.querySelector("#js-form-search-widget")
            if (formSearchWidget) {
                // on post le formulaire
                formSearchWidget.submit();
            } else {
                // récuperer l'attribut data-url pour faire une redirection
                let dataUrl = this.$btnDiscoverHotels.getAttribute("data-url");
                window.location.href = dataUrl;
            }
        })

        this.$btnValidateCar.addEventListener("click", (evt) => {
            let active = document.querySelector(".js-close-panel")
            active.click()
        })
    }
}