import { initialiserSplide } from "../components/Pagination";


export default class BonCadeau extends HTMLDivElement {


    constructor() {
        super();
    }

    connectedCallback() {
        $("#triBonCadeau").on("change", function () {
            var ordre = this.value;
            // Sélectionnez les éléments à trier en fonction de la classe "prix".
            var elements = $(".prix").closest(".card-to-order");

            // Triez ces éléments en fonction de la valeur du prix.
            elements.sort(function (a, b) {
                var prixA = parseFloat($(a).find(".prix").text().replace(/[^\d.-]/g, ''));
                var prixB = parseFloat($(b).find(".prix").text().replace(/[^\d.-]/g, ''));
                if (ordre == 0) {
                    return prixA - prixB;
                }
                else {
                    return prixB - prixA;
                }
            });

            // Videz le conteneur d'origine.
            $("#conteneurBonCadeaux").empty();

            // Ajoutez à nouveau les éléments triés au conteneur.
            elements.each(function () {
                $("#conteneurBonCadeaux").append(this);
            });

            initialiserSplide();
        });
    }
}