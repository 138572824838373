export default class SearchHeader extends HTMLDivElement {

  constructor() {
    super();

    this.$jsWidgetSetting = document.querySelector(".js-widget-setting")
    this.$SearchWidget = document.querySelector(".js-search-widget")
    this.$jsCloseSearchWidget = document.querySelector(".js-close-search-widget")

    this.$jsRevervationCard = document.querySelector(".js-reservation-card")

  }

  connectedCallback() {

    document.addEventListener('search-header-opened', e => {
      this.classList.add('opened')
      this.$jsRevervationCard.style.top = "184px"
    })

    if(this.$jsWidgetSetting) {
      this.$jsWidgetSetting.addEventListener("click", () => {
        this.$SearchWidget.classList.add("open")
      })
    }

    this.$jsCloseSearchWidget.addEventListener("click", () => {
      this.$SearchWidget.classList.remove("open")
    })
  }
}