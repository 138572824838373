export default class HotelFilter extends HTMLDivElement {


    constructor() {
        super();
        this.$checkVille = document.querySelector("#checkVille")
        this.$checkCampagne = document.querySelector("#checkCampagne")
        this.$checkBordDeMer = document.querySelector("#checkBordDeMer")
        this.$sortList = document.querySelector("#sortList")
        this.$filterBtn = document.querySelector("#filterBtn")
        this.$eraseBtn = document.querySelector("#eraseBtn")
        this.$cardsHotel = document.querySelectorAll(".cardHotelList")
        this.$eqts = document.querySelectorAll(".js-eqt")
        this.$allChecks = document.querySelectorAll(".js-check")
    }

    connectedCallback() {
        let self = this;
        this.$filterBtn.addEventListener("click", (evt) => {
            self.filter();
        })

        // au changement de selection du tri
        this.$sortList.addEventListener("change", (evt) => {
            self.filter();
        })

        // au bouton sur effacer les filtres
        this.$eraseBtn.addEventListener("click", (evt) => {

            // on decoche toutes les cases
            this.$allChecks.forEach(function (checkbox) {
                checkbox.checked = false;
            });

            // on reaffiche toutes les cartes hotels
            this.$cardsHotel.forEach(($cardHotel) => {
                $cardHotel.style.display = "";
            })
        })
    }

    filter() {

        // V�rifier si au moins un �l�ment de this.$eqts est coch�
        let auMoinsUnCoche = false;

        // Parcourir les �l�ments de this.$eqts
        this.$eqts.forEach(($eqt) => {
            // V�rifier si l'�l�ment est coch�
            if ($eqt.checked) {
                auMoinsUnCoche = true;
                return; // Sortir de la boucle forEach une fois qu'un �l�ment est coch�
            }
        });

        // on filtre pas si rien n'est coch�
        if (this.$checkVille.checked != false
            || this.$checkCampagne.checked != false
            || this.$checkBordDeMer.checked != false
            || auMoinsUnCoche) {

            // on cache toutes les cartes puis on affiche celle filtres
            this.$cardsHotel.forEach(($cardHotel) => {
                $cardHotel.style.display = "none";
            })
        }

        // Filtre
        this.$cardsHotel.forEach(($cardHotel) => {
            var isDisplayable = false;
            if (this.$checkVille.checked == false && this.$checkCampagne.checked == false && this.$checkBordDeMer.checked == false) {
                isDisplayable = true;
            }
            else if (this.$checkVille.checked == true && $cardHotel.dataset.emplacement == 0) {
                isDisplayable = true;
            } else if (this.$checkCampagne.checked == true && $cardHotel.dataset.emplacement == 1) {
                isDisplayable = true;
            } else if (this.$checkBordDeMer.checked == true && $cardHotel.dataset.emplacement == 2) {
                isDisplayable = true;
            }

            var hasAllAskedEqt = true;
            this.$eqts.forEach(($eqt) => {
                if ($eqt.checked == true && !$cardHotel.dataset.eqts.includes($eqt.id)) {
                    hasAllAskedEqt = false;
                    return;
                }
            })

            if (hasAllAskedEqt && isDisplayable) {
                $cardHotel.style.display = "";
            }

        })

        // TRI
        var grid = document.getElementById('listHotel');
        var cards = Array.from(grid.getElementsByClassName('card-offre'));

        var triValue = Number(this.$sortList.value);

        // M�langer al�atoirement les cartes
        cards.sort(function (a, b) {

            switch (triValue) {
                case 0:
                    var numberA = parseInt(a.getAttribute('data-prix'));
                    var numberB = parseInt(b.getAttribute('data-prix'));
                    return numberA - numberB;
                    break;
                case 1:
                    var numberA = parseInt(a.getAttribute('data-prix'));
                    var numberB = parseInt(b.getAttribute('data-prix'));
                    return numberB - numberA;
                    break;
                case 2:
                    var numberA = parseInt(a.getAttribute('data-classement'));
                    var numberB = parseInt(b.getAttribute('data-classement'));
                    return numberA - numberB;
                    break;
                case 3:
                    var numberA = parseInt(a.getAttribute('data-classement'));
                    var numberB = parseInt(b.getAttribute('data-classement'));
                    return numberB - numberA;
                    break;
                default:
            }
        });

        // Supprimer les cartes de la grille
        while (grid.firstChild) {
            grid.removeChild(grid.firstChild);
        }

        // R�ins�rer les cartes dans la grille dans le nouvel ordre
        cards.forEach(function (card) {
            grid.appendChild(card);
        });

    }
}