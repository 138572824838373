export default class FormCommandBC extends HTMLFormElement {

    constructor() {
        super();
        this.$DupliquerAdresse = this.querySelector("#DupliquerAdresse");
        this.$NomClient = this.querySelector('input[name="Nom"]');
        this.$PrenomClient = this.querySelector('input[name="Prenom"]');
        this.$VilleClient = this.querySelector('input[name="Ville"]');
        this.$CodePostalClient = this.querySelector('input[name="CodePostal"]');
        this.$NomAdresse = this.querySelector('input[name="Adresse.Nom"]');
        this.$PrenomAdresse = this.querySelector('input[name="Adresse.Prenom"]');
        this.$VilleAdresse = this.querySelector('input[name="Adresse.Ville"]');
        this.$CodePostalAdresse = this.querySelector('input[name="Adresse.CodePostal"]');
    }

    connectedCallback() {
        let self = this;
        this.$DupliquerAdresse.addEventListener("click", (evt) => {
            if (self.$DupliquerAdresse.checked) {
                self.$NomAdresse.value = self.$NomClient.value;
                self.$PrenomAdresse.value = self.$PrenomClient.value;
                self.$VilleAdresse.value = self.$VilleClient.value;
                self.$CodePostalAdresse.value = self.$CodePostalClient.value;
            }
        })
    }
}