export default class SiteHeader extends HTMLElement {

    constructor() {
        super()
        this.$nav = this.querySelector('.js-nav')
        this.$toggleNav = this.querySelector('.js-toggle-nav')
        this.$toggleSearch = this.querySelector('.js-toggle-search')
        this.$overlayHeader = this.querySelector(".bg-overlay")
    }

    connectedCallback() {

        if (this.$toggleNav) {
            this.$toggleNav.addEventListener('click', e => {
                if (this.$nav.classList.contains('opened')) {
                    this.closeNav()
                } else {
                    this.openNav()
                }
            })
        }

        window.addEventListener('resize', e => {
            this.closeNav()
        })

        document.addEventListener('click', e => {
            if (this.$nav) {
                if (this.$nav.classList.contains('opened') && !this.contains(e.target)) {
                    this.closeNav()
                }
            }
        }
        )

        if (this.$toggleSearch) {
            this.$toggleSearch.addEventListener('click', e => {
                document.dispatchEvent(new Event('search-header-opened', { bubbles: true }))
            })
        }

        if (this.$overlayHeader) {
            this.$overlayHeader.addEventListener("click", () => {
                this.closeNav()
            })
        }
    }

    openNav() {
        this.$nav.classList.add('opened')
        this.$toggleNav.classList.add('active')
    }

    closeNav() {
        if (this.$nav) {
            this.$nav.classList.remove('opened')
        }
        if (this.$toggleNav) {
            this.$toggleNav.classList.remove('active')
        }
    }

}