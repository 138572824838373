import Splide from '@splidejs/splide';

export function initialiserSplide() {

    const scrollbarWidth = window.innerWidth - document.body.clientWidth;
    document.body.style.setProperty("--scrollbarWidth", `${scrollbarWidth}px`);

    const elems = document.querySelectorAll('.splide');

    elems.forEach(elem => {

        const splide = new Splide(elem);
        const count = document.createElement('div');
        let totalPages = 0;
        let perPage = 0;
        const totalItems = elem.querySelectorAll('.splide__slide').length;
        count.classList.add('splide__count');

        splide.on('arrows:mounted', (prev, next) => {
            prev.after(count);
        });

        splide.on('pagination:mounted', (data) => {
            totalPages = data.items.length;
            perPage = Math.ceil(totalItems / totalPages);
            count.innerHTML = '1/' + totalPages;
            if (totalPages < 2) {
                elem.querySelector('.splide__arrows').style.display = 'none';
            } else {
                elem.querySelector('.splide__arrows').style.display = 'flex';
            }
        });

        splide.on('moved', (newIndex) => {
            const currentIndex = newIndex + 1;
            let currentPage = Math.ceil(currentIndex / perPage);
            if (!Number.isInteger(totalItems / perPage) && currentIndex > totalItems - perPage) {
                currentPage = currentPage + 1;
            }
            count.innerHTML = currentPage + "/" + totalPages;
        });

        splide.mount();
    });
}
